import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const AGS = () => {
    return <section className='AGS'>
        <MatchTable betType='AGS'/>
        <Remark />
    </section>
}
export default AGS;