import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { handleClickMenuItem, MenuIcon } from './MenuCommon';
import { getSiteCoreImagePath } from "../Common/home-common";
import { Button, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { use2ndLvlMenuQuery } from '../Home/Data/SitecoreDataHooks';
import { useLoginLogout } from '../BetSlip/LoginHooks';
import { useLoginLogout_IB } from '../BetSlip/LoginIBHooks';

const SecondLvlMenu = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [menuLoading, menuData] = use2ndLvlMenuQuery();
    // const { isPCardUser } = window.globalConfig.IS_IB ? useLoginLogout_IB() : useLoginLogout();

    const handleOnPageChange = (url) => {
        navigate(url);
    }

    // useEffect(() => {
    //     function handleResize() {
    //         const secMenu = document.querySelector('.secMenu')
    //         const betslipContainer = document.querySelector('#betslip-container')
    //         const topMenuUl = document.querySelector('.topNav ul')
    //         let secMenuLength = 0
    //         let betslipContainerLength = 0
    //         let secMenuLengthMarginRight = '24px'
    //         if(secMenu) {
    //             secMenuLength = secMenu.clientWidth
    //             var computedStyle = getComputedStyle(secMenu, null);
    //             secMenuLengthMarginRight = computedStyle.marginRight
    //         }
    //         if(betslipContainer) betslipContainerLength = betslipContainer.clientWidth
    //         if(topMenuUl) {
    //             topMenuUl.style.maxWidth = `calc(100vw - ${secMenuLength}px - ${betslipContainerLength}px - ${secMenuLengthMarginRight})`
    //             topMenuUl.style.minWidth = `325px`
    //         }
    //     }
    //     window.addEventListener('resize', handleResize);
    //     handleResize();
    //     return () => window.removeEventListener('resize', handleResize);
    // });

    return !menuLoading ? <ul key="topMenuUl">
            {menuData.map((menu, idx) => {
                if ( menu.itemEnable?.value!=="1") {
                    return null;
                }
                else if ( menu?.pCardEnable?.value==="1" && sessionStorage.getItem('speedbet_cust') != 'Y') {
                    return null;
                }
                else if (menu.children.filter(x=> x.itemEnable?.value==="1").length > 0 ) {
                    const menuVariant = menu.key.replace(/\s/g, '')
                    return <li key={`topMenu-${menu.key}`} className="inline-block">
                        <DropdownButton
                            as={ButtonGroup}
                            key={`DropdownButton-${menu.key}`}
                            id={`dropdown-variants-${menu.key}`}
                            variant={menu.itemName.value}
                            menuVariant={menuVariant}
                            title={<>{menu.itemName.value}<MenuIcon menu={menu} /></>}
                        >
                            <MenuItem menu={menu} handleOnPageChange={handleOnPageChange}/>
                        </DropdownButton>
                    </li>
                }
                else {
                    return <li key={`topMenu-${menu.key}`} className="inline-block">
                        <div className={`dropdown btn-group`}>
                            <Button onClick={(e)=>handleClickMenuItem(e, menu, handleOnPageChange)} value={menu.itemName.value} variant="">{menu.itemName.value}<MenuIcon menu={menu} /></Button>
                        </div>
                    </li>
                }
            })}
        </ul> : null;
}



export default SecondLvlMenu;

const MouseOverBox = ({menu}) => {
    const {i18n} = useTranslation()
    return <>
        <div><img src={getSiteCoreImagePath(menu.moImage.src, i18n.language)} title={menu.moHead.value} /></div>
        <div className='RATContent'>
            <div>{menu.itemName.value}</div>
            <div>{menu.moText.value}</div>
        </div>
    </>;
}

const MenuItem = ({menu, handleOnPageChange}) => {

    const [activeRAT, setActiveRAT] = useState('')

    const setMouseOver = (key, show) => {
        // document.querySelector('.' + id).style.display = show ? "flex" : "none";
        if(show){
            setActiveRAT(key)
        }else{
            setActiveRAT('')
        }
    }

    if(menu.key === "Int_Retail And Telebet"){
        return <div className={`retailAndTelebet ${ activeRAT != '' ? 'activeDetail' : ''}`}>
            <div className='retailAndTelebet-l'>
                {
                    menu.children.map((_cItem, _cIndex) => {
                        if ( _cItem.itemEnable.value!=="1" ) {
                            return null;
                        }
                        else if ( _cItem.moHead.value!=="" ) {
                            return <div className={`RATdropdown-item ${ _cItem.key === activeRAT ? 'active' : ''}`} onMouseEnter={()=> {setMouseOver(_cItem.key, true)}} onMouseLeave={()=> {setMouseOver(_cItem.key, false)}} eventKey={_cIndex} onClick={(e) => handleClickMenuItem(e, _cItem, handleOnPageChange)} value={_cItem.itemName.value} key={_cItem.itemName.value} >
                                {_cItem.itemName.value}<MenuIcon menu={_cItem} />

                            </div>
                        }
                    })
                }
            </div>
            <div className={`retailAndTelebet-r  ${ activeRAT != '' ? 'active' : ''}`}>
            {
                    menu.children.map((_cItem, _cIndex) => {
                        if ( _cItem.itemEnable.value!=="1" ) {
                            return null;
                        }
                        else if ( _cItem.moHead.value!=="" ) {
                            return <div className={`mo-${_cItem.key} ${_cItem.key === activeRAT ? 'active' : ''} topNavMouseOver`} key={menu.key}><MouseOverBox menu={_cItem} /></div>
                        }
                    })
                }

            </div>
        </div>
    }
    return menu.children.map((_cItem, _cIndex) => {
        if ( _cItem.itemEnable.value!=="1" ) {
            return null;
        }
        else if ( _cItem.moHead.value!=="" ) {
            return null
        }
        else {
            return <Dropdown.Item eventKey={_cIndex} onClick={(e) => handleClickMenuItem(e, _cItem, handleOnPageChange)} value={_cItem.itemName.value} key={_cItem.key} >
            {_cItem.itemName.value}<MenuIcon menu={_cItem} />
            </Dropdown.Item>;
        }
    });
}