import React,{ useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NewWindow } from '../../Common/home-common';
import { setMyFavCookie, MyFavouritesContext } from './MyFavourites'
import { MarksixContext } from '../../Home/MarksixPage';
import { isStartSell } from './Common';
import { RrawResultContext } from '../Page/CheckDrawResult';
import {useBetSlip, AddM6SlipFunc} from '../../BetSlip/BetSlipHooks'
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { useWindowSize, useShowAlertDialog } from '../../Common/CommonHooks';
import { isPmuEoD, eodMessage } from '../../Common/GlobalFunc';

const  Instructions = ({selectedData,clear,tableType,pageType='',onChangeCheckTable, clearPartBall}) => {
  const { t } = useTranslation()
  const context = useContext(MarksixContext);
  const isSell = isStartSell(context.nextDraw);
  const [openIns, setOpenIns] = useState(false)
  const [canAddToSlip,setCanAddToSlip] = useState(true)
  const { updateMyFavourites, curpageFavCheckArr, setFavInitCheckState } = useContext(MyFavouritesContext)
  const { addToBetSlip, betLines } = useBetSlip()
  const { isMobileSize } = useWindowSize();
  const { checkRrawNumber, setCheckRrawNumber } = useContext(RrawResultContext)
  const pageName = context.pageName;
  const { getAlertShowMode } = useShowAlertDialog();


  useEffect(()=>{
    let num = 0
    let canSave = true
    if (tableType === 'single') {
      selectedData.forEach(item => {
        if (item.numList.length >= 6) {
          num++
        }
        else if (item.numList.length > 0) canSave = false
        else if (item.numList.length === 0 && item.isFav) canSave = false
      })
    }
    else if (tableType === 'multiple') {
      if (selectedData[0].numList.length >= 6) {
        num = selectedData[0].chance
      }
    }
    else if (['random', 'banker'].includes(tableType)) {
      selectedData.forEach(item => {
        const cha = item.chance || 0
        num += cha
      })
    }
    if (num === 0) canSave = false
    setCanAddToSlip(canSave)
  },[selectedData])

  const handleClickDemoLink = () => {
    let url = t('LB_M6_SELECT_NUMBERS_DEMO_LINK');
    NewWindow(url, '', 770, 500, 1, 1);
  }

  const addToSlipvalidation = (needAlert = true,mobileIgnore = false) => {
    let favCheckedList = isMobileSize?curpageFavCheckArr.filter(item => item.checked):[];
    if (!isSell && !mobileIgnore) {
      if (needAlert) {
        if (isPmuEoD() && eodMessage()?.length > 0) {
            getAlertShowMode(eodMessage())  
          } else {
            getAlertShowMode(t('LB_M6_MSG_NOT_DIFINED'))
          }
        } 
        return false
    }
    if (!canAddToSlip ) {
        if(favCheckedList.length>0){
          if(!needAlert){
            return true
          }
          const isCanAdd= ((!canAddToSlip) && (selectedData[0]?.bankerList?.length > 0 || selectedData[0]?.numList?.length > 0 )) ? false:true;

          if(!isCanAdd){
            getAlertShowMode(t('LB_M6_MSG_INSU_SELECTIONS'))
          }
          return isCanAdd
        }else{
          needAlert && getAlertShowMode(t('LB_M6_MSG_INSU_SELECTIONS'))
          return false
        }
    }
    if (selectedData[0]?.bankerList?.length > 0 && (selectedData[0].bankerList.length + selectedData[0].numList.length) < 7 ) {
      if(favCheckedList.length>0){
        if(!needAlert){
          return true
        }
        getAlertShowMode(t('LB_M6_MSG_INSU_SELECTIONS'))
        return false
      }else{
        needAlert  && getAlertShowMode(t('LB_M6_MSG_INSU_SELECTIONS'))
        return false
      }
    }
    for (let i = 0; i < selectedData.length; i++) {
      if (selectedData[i].numList.length >= 49 && !mobileIgnore) {
        needAlert && getAlertShowMode(t('LB_M6_MSG_SELECT_NUMBERS_MULT_49'))
        return false
      }
    }
    return true
  }

  const handleClickAddToSlip = () => {
    if (!addToSlipvalidation(true)) return false

    const betList = AddM6SlipFunc([...selectedData], context)
    let successAdd = addToBetSlip(betList)
    if (successAdd.acceptResult) {
      const isPassPartBet = successAdd.acceptType == 'part';
      if (isPassPartBet && clearPartBall) {
        clearPartBall(0, successAdd.acceptBetSlipLength)
      } else {
        clear();
      }
    }
    if (pageType === 'checkdrawresult') {
      onChangeCheckTable(false)
    }
    glassboxCustomEvent("Add_to_Slip");
  }

  return <div className='ball-panel-instructions'>
    <div className='ball-panel-instructions-top'>
      <div className='df instructions-left'>
        <div className='ms-switch-btn-icon'>
          <ArrowIcon onClick={()=> setOpenIns(!openIns)} className='hueRotate' direction='down' change={openIns} changeDirection='up' size={20} />
        </div>
        {t('LB_M6_HOW_TO_SELECT')}</div>
      <div className='instructions-btn'><button id="Add_to_Slip" className='add-to-slip AddToSlip' title={t('LB_ADDTOSLIP')} onClick={handleClickAddToSlip}>{t('LB_ADDTOSLIP')}</button></div>
    </div>
    {openIns && <div className='ball-panel-instructions-bottom'>
      <div ><span>{t(`LB_M6_MSG_SELECT_NUMBERS_HELP${tableType ==='banker'?'5':'1'}`)}</span></div>
      <div ><span>{t(`LB_M6_MSG_SELECT_NUMBERS_HELP${tableType ==='banker'?'6':'2'}`)}</span></div>
      {/* <div ><span>{t('LB_M6_MSG_SELECT_NUMBERS_HELP3')}</span><span className='ball-panel-help-link' onClick={handleClickDemoLink} alt=''>{t('LB_M6_MSG_SELECT_NUMBERS_DEMO')}</span><span>{t('LB_M6_MSG_SELECT_NUMBERS_HELP4')}</span></div> */}
    </div>}
  </div>
}

export default Instructions