import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const LGS = () => {
    return <section className='LGS'>
        <MatchTable betType='LGS'/>
        <Remark />
    </section>
}
export default LGS;