import ArrowIcon from './ArrowIcon';
import { useTranslation } from 'react-i18next';
const MoreButton = ({expand,MoreButtonClick,showLessText="Hide",showMoreText="More", showLine=false, id})=>{
    const { t } = useTranslation();
    return (
        <div
            className={`sgaMoreButton ${showLine? 'showLine': ''}`}
            onClick={MoreButtonClick}
            id={id}
        >
            <div>{expand ? t(showLessText) : t(showMoreText)}</div>
            <ArrowIcon color="green" className="" direction={expand ? 'up' : 'down'} size={24} />
        </div>)
}

export default  MoreButton