import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import CashOutButton from './CashOutButton'
import { useWindowSize } from '../../Common/CommonHooks';
import { formatNumber, textFormatAmountFn } from '../../Common/GlobalFunc';
import { ClockIcon,
    isMatchKickOff,
    isExtraTimeMatch,
    getInplayState,
    getRunningResult,
    getNumberSuffix,
    goToAllOddsPage,
    getIsCrossPoolAllup,
    isMatchVoided,
    isInplayMatch,
    isMatchEnded,
    isMatchSuspended,
    isAllPoolRefund,
    isPayOutOrRefund,
    isTtgPendingForResult,
    isSettledLeg,
    isIrrationalAll,
    showRunningResult,
} from '../../Football/Common/MatchBase';
import { toDateFormat, toTimeFormat } from '../../Football/Common/Common';
import { ESCombination } from './CashOutBase';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { FootballContext } from '../FootballPage';
import { filterESMatchData } from '../../Football/Common/ResultsBase';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';

const CashOutFootball = (props) => {
    const { data, esValue, esStates, isAllOddsPage ,isMoreButton,showMoreBtn, onClickMoreButton } = props;
    const { t, i18n } = useTranslation()
    const { isMobileSize } = useWindowSize();
    const navigate = useNavigate();
    const context = useContext(FootballContext);

    const isAllup = data.allUpFormula && data.allUpFormula != ''
    let isCrossPoolAllup = getIsCrossPoolAllup(data)
    let legs = showMoreBtn[data.accountTranNo] ? data.legs : data.legs.slice(0,3);
    const matchs = legs.map(item => item.match);

    let isLoading='';
    if(Object.values(esStates).length>0){
        Object.values(esStates).forEach(state => {
            if(state==='loading'){
                isLoading='loading';
            }
        });
    }

    const  amountProps = { isSettledLeg, isMatchVoided, isIrrationalAll, isAllup, isCrossPoolAllup, data, legs, context, ESCombination, ESLoadMoreButtonUI, isMobileSize,
        isMoreButton, showMoreBtn, onClickMoreButton }
    return matchs && matchs.length > 0 ? <div className={`cashOutFootball cashOutItem ${esStates[data.uniqueTicketId]!=null && esStates[data.uniqueTicketId]!='' && !isMobileSize && 'cashOutItem-bigH'}`}>
        <div className={`cashOutItemHeader ${!isMobileSize && 'right-border'}`}>
            {isMobileSize && `${t('LB_CASHOUT_REF_NO_S')} `}{data.accountTranNo}
        </div>
        <div className={`matchInfoContainer ${!isMobileSize && 'matchInfoContainer-d right-border'}`}>
            {isMobileSize && data?.legs.map(item => item.match).length > 1 ? <>
                <Swiper modules={[Pagination]} className="es-swiper-container" pagination={{
                    clickable: true,
                }}>
                    {data?.legs.map(item => item.match).map((item, index)=>{
                        return <SwiperSlide key={item.id}>
                            <div className='matchInfo-m'>
                                {index == 0 && <ResultMsgUI esStates={esStates} data={data} />}
                                {isAllOddsPage && !isMobileSize && <AmountUI {...amountProps}/>}
                                {!isAllOddsPage && <MatchInfoUI match={item} leg={legs[index]} isMobileSize={isMobileSize} isLoading={isLoading} context={context}/>}
                            </div>
                        </SwiperSlide>
                    })}
                </Swiper>
            </> : <>
                {matchs.map((item, index)=>{
                    return <div className='matchInfo' key={item.id}>
                        {index == 0 && <ResultMsgUI esStates={esStates} data={data} />}
                        {isAllOddsPage && !isMobileSize && <AmountUI {...amountProps}/>}
                        {!isAllOddsPage && <MatchInfoUI match={item} leg={legs[index]} isMobileSize={isMobileSize} isLoading={isLoading} context={context}/>}
                    </div>
                })}
                <ESLoadMoreButtonUI {...amountProps}/>
            </>}
        </div>
        <div className='betslipInfo'>
            {(!isAllOddsPage || isMobileSize) && <AmountUI {...amountProps} />}
            <CashOutButton
                data={data}
                esValue={esValue}
                isMobileSize={isMobileSize}
            />
        </div>
    </div> : null
}

export default CashOutFootball

const ESLoadMoreButtonUI = (props) => {
    const {t, i18n} = useTranslation()
    const { data={}, isMoreButton, showMoreBtn, onClickMoreButton } = props
    return isMoreButton && <div className="ESLoadMoreButton" onClick={(e) => onClickMoreButton(data.accountTranNo, e)}>
        {t(showMoreBtn[data.accountTranNo] ? 'LB_FB_SHOW_LESS' :'LB_FB_ES_SHOW_ALL')}
    </div>
}

const UnitBetUI = (props) => {
    const {t, i18n} = useTranslation()
    const lang = i18n.language;
    const { data={}, isMobileSize  } = props

    return <>
        <div>{t('LB_CASHOUT_UNIT_BET')}:</div>
        <div>${textFormatAmountFn(data.unitBetAmt / 100, 0)}</div>
        {isMobileSize && <MaxDividendUI {...props} />}
    </>
}

const MaxDividendUI = (props) => {
    const {t, i18n} = useTranslation()
    const lang = i18n.language;
    const { data={} } = props
    const remainBetTotal = t('LB_CASHOUT_REMAINING')
    const remainBetTotalList = t('LB_CASHOUT_REMAINING').split(' ')
    const remainBetTotal1 = lang === 'en' ? <div>{remainBetTotalList[0]} <br/>{remainBetTotalList[1]} {remainBetTotalList[2]}</div> : remainBetTotal;
    return <>
        {/* data.allUpLevel > 1 ? <div>{remainBetTotal1}</div> : null */}
        {/* data.allUpLevel > 1 ? <div>${textFormatAmountFn(data.remainingBetTotal / 100, 0)}</div> : null */}
        {data.allUpLevel == 1 ? <div>{t('LB_CASHOUT_MAX_DIVIDEND')}:</div> : null}
        {data.allUpLevel == 1 ? <div>${formatNumber(data.unitBetAmt / 100 * parseFloat(data?.legs?.[0]?.combinations?.[0].oddsValue), 2, true, false, true)}</div> : null}
    </>
}

const AmountUI = (props) => {
    const { isMobileSize } = props
    return isMobileSize ?
        <div className='dividendInfoContainer'>
            <div className='dividendInfo-top'>
                <BetTypeUI {...props}/>
                <div className='unitBet textAlignR'>
                    <UnitBetUI {...props} />
                </div>
            </div>
        </div>
        :
        <div className='dividendInfoContainer dividendInfoContainer-d'>
            <BetTypeUI  {...props}/>
            <div className='betDivInfo'>
                <div className='unitBet textAlignR'>
                    <UnitBetUI {...props} />
                </div>
                <div className='unitBet textAlignR'>
                    <MaxDividendUI {...props} />
                </div>
            </div>
        </div>
}

const ResultMsgUI = ({esStates, data}) => {
    const {t, i18n} = useTranslation()
    const [msgText, setMsgText] = useState(null)
    let timeOutRef = useRef(true)

    useEffect(()=>{
        if(timeOutRef.current){
            timeOutRef.current = false
            switch(esStates[data.uniqueTicketId]){
                case "successful":
                    setMsgText('LB_CASHOUT_ACCEPTED')
                    break;
                case "rejected":
                case "suspend_rejected":
                    setMsgText('LB_CASHOUT_REJECTED')
                    break;
                case "value_rejected":
                    setMsgText('LB_CASHOUT_VALUE_REJECTED')
                    break;
                case "jsEarlySettlementRejected1":
                    setMsgText('LB_CASHOUT_REJECTED1')
                    break;
                case "jsEarlySettlementRejected2":
                    setMsgText('LB_CASHOUT_REJECTED2')
                    break;
                case "jsEarlySettlementRejected3":
                    setMsgText('LB_CASHOUT_REJECTED3')
                    break;
                case "jsEarlySettlementRejected4":
                    setMsgText('LB_CASHOUT_REJECTED4')
                    break;
                case "jsEarlySettlementRejected6":
                    setMsgText('LB_CASHOUT_REJECTED6')
                    break;
                default:
                    setMsgText(()=>{
                        timeOutRef.current = true
                        return null
                    })
                    break;
            }
            if(!timeOutRef.current ){
                setTimeout(()=>{
                    timeOutRef.current = true
                },3000)
            }
        }

    },[data, esStates])

    const isSuccessText = esStates[data.uniqueTicketId] === 'successful' || msgText == 'LB_CASHOUT_ACCEPTED';

    return <div className='requestInfomation'>
        {msgText && <><div className={`${isSuccessText? 'icon-accepted': 'icon-rejected'} icon-s`}></div>
        <span className={`successfulText ${isSuccessText? '': 'rejectColor'}`}>{t(msgText)}</span></>}
    </div>;
}

const MatchInfoMidRightUI = (props) => {
    const {t, i18n} = useTranslation()
    const { match, leg, isLoading, context } = props
    let {homeScore, awayScore, corner} = getRunningResult(match);
    const isInPlay = isMatchKickOff(match?.status);
    const isInplayPool = isInplayMatch(match)
    const isLiveScore = ['FIRSTHALFCOMPLETED', 'FULLTIMECOMPLETED', 'SECONDHALFETCOMPLETED'].includes(match.status)
    const inplaySelling = isInPlay && isInplayPool && (isLiveScore? true : match?.poolInfo.sellingPools.length>0);
    const isDS = match.isInteractiveServiceAvailable
    homeScore = inplaySelling && isDS ? homeScore : '-';
    awayScore = inplaySelling && isDS  ? awayScore : '-';
    const isET = isExtraTimeMatch(match);
    let isVoid = isMatchVoided(match);
    let isEnd = isMatchEnded(match) || isMatchSuspended(match);
    let allRefund = isAllPoolRefund(match);
    let showArrow = !isEnd && !isVoid &&  match?.foPools.some(pool => ['SELLINGSTARTED', 'SELLINGSTOPPED'].includes(pool.status.toUpperCase()))

    const { halfTime, fullTime, extraTime } = filterESMatchData(match, t, i18n);
    const halfTimeData = halfTime.split(':')
    const fullTimeData = fullTime.split(':')
    const extraTimeData = extraTime.split(':')
    return <>
        {!isVoid ? (
            (isEnd && !allRefund) ? <div className='matchInfoMidScore'>
                {halfTime != '-' && <div className='scoreInfo'>
                    <div className='exepcted-stop-time'>{t('LB_FB_INPLAY_HT')}</div>
                    <div className={`matchInfoMid-score`}>
                        <div>{halfTimeData[0]}</div>
                        <div>{halfTimeData[1]}</div>
                    </div>
                </div>}
                {fullTime != '-' && <div className='scoreInfo'>
                    <div className='exepcted-stop-time'>{t('LB_FB_INPLAY_FT_CASHOUT')}</div>
                    <div className={`matchInfoMid-score`}>
                        <div>{fullTimeData[0]}</div>
                        <div>{fullTimeData[1]}</div>
                    </div>
                </div>}
                {extraTime != '-' && <div className='scoreInfo'>
                    <div className='exepcted-stop-time'>{t('LB_FB_INPLAY_ET')}</div>
                    <div className={`matchInfoMid-score et`}>
                        <div>{extraTimeData[0]}</div>
                        <div>{extraTimeData[1]}</div>
                    </div>
                </div>}
            </div> : <>
                {showRunningResult(match) ? <div className='matchInfoMidScore'>
                    <div className={`matchInfoMid-score ${isET ? "et" : ""}`}>
                        <div>{homeScore}</div>
                        <div>{awayScore}</div>
                    </div>
                </div> : null}
            </>
        )
        : null}
        {showArrow ? <div className='matchInfoMid-more' onClick={() => { !isLoading && goToAllOddsPage(match, context, null, true) }}>
            <ArrowIcon className={`${isLoading ? 'opacity30' : ''}`} direction='right' size={24} />
        </div> : null}
    </>
}

const MatchInfoUI = (props) => {
    const { match, leg, isMobileSize } = props
    const {t, i18n} = useTranslation()
    const isInPlay = isMatchKickOff(match?.status);
    const isInplayPool = isInplayMatch(match)
    let {homeScore, awayScore, corner, homeCorner, awayCorner } = getRunningResult(match);
    const inplaySelling = isInPlay && isInplayPool && match?.poolInfo.sellingPools.length>0;
    const hasCornerPoolDefine = match?.foPools.some(i => window.fbConfig.CornerPools.includes(i.oddsType)) && showRunningResult(match);
    let CornerStartingSell = match.poolInfo.sellingPools.some(betType => ["CHL", "FCH","CHD", "FHC"].includes(betType)) ;
    let ETCornerStartingSell = match.poolInfo.sellingPools.some(betType => ['ECH', 'ECD'].includes(betType)) && match.status.toUpperCase() != 'FULLTIMECOMPLETED' ;
    const isCornerPoolSelling = CornerStartingSell || ETCornerStartingSell;
    const isDS = match.isInteractiveServiceAvailable;
    corner = inplaySelling && isCornerPoolSelling && isDS  ? `${corner}(${homeCorner}:${awayCorner})` : '---';
    const matchDate = toDateFormat(match?.kickOffTime, false, true);
    let isVoid = isMatchVoided(match);
    let isEnd = isMatchEnded(match) || isMatchSuspended(match);
    let allRefund = isAllPoolRefund(match);
    const { halfCorner, fullCorner, extraCorner, hasHalfTimeCorner, hasFullTimeCorner, hasExtraTimeCorner } = filterESMatchData(match, t, i18n);

    return <>
        <div className='matchName'>{match.frontEndId}<span>{match.tournament["name_"+i18n.language]}</span></div>
        <div className='df matchInfoMid'>
            <div className='matchInfoMid-team'>
                <div>{match.homeTeam["name_"+i18n.language]}</div>
                <div>{match.awayTeam["name_"+i18n.language]}</div>
            </div>
            {isMobileSize ?
             <MatchInfoMidRightUI {...props}/>
            : <div className='matchInfoMid-d'>
                <MatchInfoMidRightUI {...props} />
            </div>}
        </div>
        <div className='matchInfoBottom'>
            {isEnd ? <div className="matchCompletedContainer">
                <div div className="et">
                    {!allRefund && <>
                        {hasFullTimeCorner && <div className='matchInfoBottom-corner' title={t('LB_FB_CORNERS_TAKEN_SO_FAR')}><i className='icon-corner-FT'></i><span>{fullCorner}</span></div>}
                        {hasExtraTimeCorner && <div className='matchInfoBottom-corner' title={t('LB_FB_CORNERS_TAKEN_SO_FAR')}><i className='icon-corner-ET'></i><span className='corner-ET'>{extraCorner}</span></div>}
                    </>}
                </div>
                <div div className="match-completed">{t('LB_FB_INPLAY_' + getInplayState(match.status, '_CASHOUT').toUpperCase())}</div>
            </div> : null}
            {isVoid ? <>
                <div>
                    {matchDate}
                    <div div className="red">{t('LB_FB_RESULT_VOID_MATCH')}</div>
                </div>
            </> : <div>
                {!isEnd ? <>
                    {isInplayPool && isInPlay ? t('LB_FB_INPLAY_' + getInplayState(match.status, '_CASHOUT').toUpperCase()) : matchDate}
                    {isInplayPool ? <div className='icon-clock'><ClockIcon match={match} /></div> : null}
                    {isInPlay && isInplayPool && hasCornerPoolDefine && <div className='matchInfoBottom-corner' title={t('LB_FB_CORNERS_TAKEN_SO_FAR')}><i className='icon-corner'></i><span>{corner}</span></div>}
                </> : null}
            </div>}
        </div>
    </>
}

const BetTypeUI = (props) => {
    const { isSettledLeg, isMatchVoided, isIrrationalAll, isAllup, isCrossPoolAllup, data={}, legs, context, ESCombination, ESLoadMoreButtonUI } = props
    const {t, i18n} = useTranslation()
    return <div className='betTypeInfo'>
        {isAllup && <div className='esSingleMatchBet'>{t(isCrossPoolAllup ? 'LB_RBC_ALUP' : 'LB_ALLUP')} {data.allUpFormula}</div>}
        {legs.map((item,index)=>{
            let goalInfo = '';
            if ( context.content.config.NTSPools.includes(item?.pool?.oddsType) ) {
                let instNo = item.pool.instNo;
                let ngoal = `${instNo}${i18n.language=="en" ? getNumberSuffix(instNo) : ''}`;
                goalInfo = `(${t('LB_FB_N_GOAL').replace('{0}', ngoal)})`;
            }
            const showDim = (isSettledLeg(legs[index], item.match) || isIrrationalAll(legs[index])); 
            return <div className={`betType ${showDim ? 'esDimmed' : ''}`} key={item.eventId}>
                {isAllup && <>
                    <div className='matchName'>{item.match.frontEndId}</div>
                    <div className='matchInfoMid-team'>
                        <div>{item.match.homeTeam["name_"+i18n.language]}</div>
                        <div className='matchVS'>{i18n.language=="en" ? 'VS' : '對'}</div>
                        <div>{item.match.awayTeam["name_"+i18n.language]}</div>
                    </div>
                </>}
                <div className='dividendInfo-betType'>
                    {`${t(`LB_FB_TITLE_${item?.pool?.oddsType}`)} ${goalInfo}`}
                </div>
                <div className='dividendInfo-betComb'><ESCombination item={data} legNo={index} /></div>
            </div>
        })}
        <ESLoadMoreButtonUI {...props} />
    </div>
}