import React, { useContext, useEffect, useState } from 'react';
import Tabs from '../../../Common/ui-components/Tabs';
import Table from '../../../Common/ui-components/Table';
import { FootballContext } from '../../../Home/FootballPage';
import { useTranslation } from 'react-i18next';
import FGSTable from './../FGSTable';
import { useWindowSize } from '../../../Common/CommonHooks';
import './index.scss'

const GSTabTable =({foPool = [],getOddsVal, language,getTeamVal})=>{
    const { isMobileSize } = useWindowSize();
    const context = useContext(FootballContext);
    const { t } = useTranslation();
    const PlayerBetTypeFilteringTab = window.fbConfig?.PlayerBetTypeFilteringTab
    const [selected, setSelected] = useState("GS")
    let list = [{...PlayerBetTypeFilteringTab[0],id:'GSTabTable_GS'}]
    const messgeSuffix = context.state.windowSize.isMobileSize ? ' - Mobile' : ' - Desktop';
    
    foPool.sort((a,b)=>{
        return PlayerBetTypeFilteringTab.findIndex(i=>a.oddsType == i.key) - PlayerBetTypeFilteringTab.findIndex(i=>b.oddsType == i.key)
    })

    let foPoolData = {}
    let columns = [
        {
          title:t('LB_FB_GSTABLE_GS'),
          onCell: (param)=>{
            const selection= param.selections[0]
            const abbr = ['','H', 'A']
            const str = selection.str
            if (param.str === '000') {
                return t('LB_FB_GSTABLE_NONE')
            }
            return `(${abbr[str[0]]}) ${str} ${selection['name_'+language]}`
          }
        }
    ]
    foPool.map((i)=>{
        const oddsType = i.oddsType
        const index = PlayerBetTypeFilteringTab.findIndex(e=>e.key === oddsType)
        if (index !=-1) {
            list.push({...PlayerBetTypeFilteringTab[index], id: 'GSTabTable_'+PlayerBetTypeFilteringTab[index].key})
        }
        foPoolData[oddsType] = i
        columns.push({
            title: t('LB_FB_GSTABLE_'+oddsType),
            onCell:(param)=>{
                return CheckBoxCell(param, oddsType,foPoolData[oddsType])
            }
        })
    })
    
    const getSelections = (foPools=[]) =>{
        let foPool = foPools.find(pool => pool.oddsType == 'FGS') || foPools.find(pool => pool.oddsType == 'LGS')
        if(!foPool) return

        let combinations = foPool.lines[0].combinations.filter(comb => {
            let fgsCmb = foPoolData['FGS']?.lines?.[0]?.combinations?.find(c => c.str == comb.str)
            let agsCmb = foPoolData['AGS']?.lines?.[0]?.combinations?.find(c => c.str == comb.str)
            let lgsCmb = foPoolData['LGS']?.lines?.[0]?.combinations?.find(c => c.str == comb.str)
            return [fgsCmb,agsCmb,lgsCmb].some(i => (i && i.status != 'UNAVAILABLE'))
        })
        let sels = combinations.sort((a,b) =>{
            if(a.str == '000') return 1
            if(b.str == '000') return -1
            if(a.currentOdds != b.currentOdds) return a.currentOdds - b.currentOdds
            return a.str - b.str
        })
        return sels
    }
    const change = (item,index)=>{
        setSelected(item.key)
        window.GSTableType = item.key
    }
    const CheckBoxCell = (param,betType,data)=>{
        return getOddsVal(data.lines?.[0], param.str, isMobileSize,null,null,null,betType)
    }
 
    let isMobile = context.state.windowSize.isMobileSize;
    
    let msgKey  = selected == 'GS' ? 'PLA' :  selected
    const messageObj = context.betTypeMessages?.find(x => x.enable.value==="1" && x.key===msgKey+messgeSuffix);
    useEffect(()=>{
        // console.log(foPool, 'foPool')
        if (foPool.findIndex(i=>i.oddsType===selected)===-1) {
            setSelected('GS')
            window.GSTableType = 'GS'
        }
    },[foPool])
    return <div className='GSTabTable'>
        <Tabs list={list} selected={selected} change={change} label={language}>
            { messageObj && messageObj.message.value!='' && <div className="crsRemarks" dangerouslySetInnerHTML={{ __html: messageObj.message.value }}></div> }
                        {selected === 'GS' && <Table className="GSTable" data={getSelections(foPool)} columns={columns} DefaultDisplayumber={3} id="GS"></Table>}
            {foPool.filter(i=>i.oddsType===selected).map(i=>{
                return <>
                 <div className={`team ` + i.oddsType.toUpperCase()}>
                    {getTeamVal(foPoolData[i.oddsType], 'HOME', '', false, isMobile)}
                    {getTeamVal(foPoolData[i.oddsType], 'AWAY', '', false, isMobile)}
                </div>
                <FGSTable key={i.oddsType} foPool={foPoolData[i.oddsType]} isMobile={isMobile} getOddsVal={getOddsVal} foPools={foPool} betType={i.oddsType}/>
                </>
            })}
        </Tabs>
    </div>
}
export default GSTabTable