import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../Common/ConfigHelper'
import { textFormatAmountFn } from '../../Common/GlobalFunc';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { FootballContext } from '../FootballPage';
import { isSettledLeg, isMatchKickOff, formatBetType, isESInplayDelay, isCombESTempNotOffer, isMultipLineHasSinglePayOut } from '../../Football/Common/MatchBase';

const begins = 'begins', preview = 'preview', loading = 'loading', successful = 'successful', rejected = 'rejected', unknow = 'unknow';

const CashOutButton = ({ data, esValue, isMobileSize }) => {
    const { t } = useTranslation();
    const { betslipMenu } = useContext(ConfigContext);
    const footballContext = useContext(FootballContext);
    const globalContext = useContext(GlobalContext);
    const { isPushing } = globalContext.mqttClient;
    const esApiError = globalContext.esHook?.esApiError;
    const page = globalContext.esHook.esContentRef.current.page;
    const status = globalContext.esHook?.esStates?.[data.uniqueTicketId] || '';
    const isESRejected = status?.indexOf('ejected')>=0 && (status != 'jsEarlySettlementRejected1');
    const CashOutLoadingSeconds = betslipMenu?.CashOutLoadingSeconds
    const loadingBtnMaskRef = useRef()
    const timerRef = useRef()
    const timerBtnRef = useRef()
    const [isFBLoading, setIsFBLoading] = useState(false);
    const isResultsState = ['successful', 'rejected', 'unknown'].includes(status) || isESRejected
    const isTempNotOffer = isCombESTempNotOffer(data?.legs)
    const isShowPendingForPayout = globalContext.esHook?.esPendingForResults.filter(x=> x==data.uniqueTicketId).length>0;
    const [prevEsValue, setPrevEsValue] = useState({});
    
    const submittedESValue = useRef('');

    const handleClickCashOutBtn = () => {
        if ( !globalContext.globalRef.current.enableESButton ) return;
        if ( globalContext.esHook.esStates[data.uniqueTicketId]=='successful' ) return;
        submittedESValue.current = esValue.cashoutValue;
        globalContext.esHook.updateEsState(data.uniqueTicketId, 'preview');
        glassboxCustomEvent("Cash_Out_by_Betline");
        timerBtnRef.current = setTimeout(() => {
            handleClickCancel()
            globalContext.esHook.clearEsValue(data.uniqueTicketId);

            if(esApiError){
                alert(t('IB_ERROR_710'));
            }
        }, 30000);
    }

    const handleClickCancel = () => {
        submittedESValue.current = '';
        globalContext.esHook.updateEsState(data.uniqueTicketId, '');
        glassboxCustomEvent("Cash_Out_by_Betline_Cancel");
        timerBtnRef.current !== null && window.clearTimeout(timerBtnRef.current)
        timerBtnRef.current = null
    }

    const handleClickConfirm = () => {
        globalContext.esHook.updateEsState(data.uniqueTicketId, 'loading');
        globalContext.loginLogoutHook.resetLoginTimeout(false);
        globalContext.esHook.submittedEsBets.current.push(data.uniqueTicketId);
        setTimeout(() => {
            globalContext.esHook.updateEsState(data.uniqueTicketId, '');
            globalContext.esHook.submittedEsBets.current = globalContext.esHook.submittedEsBets.current.filter(x=> x!=data.uniqueTicketId);
        }, 10000);

        setIsFBLoading(true);

        footballContext.pushData.pubESValue(footballContext.content, data, submittedESValue.current);

        timerBtnRef.current !== null && window.clearTimeout(timerBtnRef.current)
        timerBtnRef.current = null
    }

    useEffect(() => {
        if (loadingBtnMaskRef.current?.style) {
            try {
                if (isFBLoading && !isResultsState) {
                    let stepTime = 2 * 1000 / 100
                    if(isESInplayDelay(data)){
                        stepTime = CashOutLoadingSeconds * 1000 / 100
                    }

                    let length = 0
                    timerRef.current = setInterval(() => {
                        length++
                        if (loadingBtnMaskRef.current) {
                            loadingBtnMaskRef.current.style.width = `${length}%`
                        }
                        
                        if (length == 100) {
                            clearInterval(timerRef.current)
                            timerRef.current = null
                        }
                    }, stepTime)
                } else {
                    loadingBtnMaskRef.current.style.width = 0
                    clearInterval(timerRef.current)
                    timerRef.current = null
                }
            } catch (error) {
                console.error(error);
            }
        }
        else {
            clearInterval(timerRef.current);
            timerRef.current = null
        }
    }, [isFBLoading, CashOutLoadingSeconds, status])

    useEffect(()=>{
        if(status==='successful' && esValue?.cashoutValue>0 && !prevEsValue.cashoutValue){
            setPrevEsValue(esValue)
        }
    },[status,esValue])

    useEffect(()=> {
        if ( page!="CASHOUT" || !window.globalConfig.FB_ODDS_PUSH || !isPushing) {
            globalContext.esHook.clearEsValue(data.uniqueTicketId);
        }
    }, [page, isPushing]);

    useEffect(()=> {
        if(isMobileSize){
            const visibilitychange = ()=>{
                if(document.visibilityState == 'visible'){
                    globalContext.esHook.clearEsValue(data.uniqueTicketId);
                }
            }
            document.addEventListener('visibilitychange', visibilitychange)
    
            const pageshow = ()=>{
                globalContext.esHook.clearEsValue(data.uniqueTicketId);
            }
            document.addEventListener('pageshow', pageshow)
    
            return ()=>{
                document.removeEventListener('visibilitychange', visibilitychange)
                document.removeEventListener('pageshow', pageshow)
            }
        }
    }, []);

    let cashoutDiv=textFormatAmountFn(esValue ? esValue.cashoutValue : "--",1)
    const formatedSubmittedESValue = textFormatAmountFn(submittedESValue.current, 1);
    let isShowSuspend = isTempNotOffer || !esValue || esValue.cashoutValue<=0 || esValue.blockedForSell
        || ['suspended', 'suspend_rejected'].includes(status);
    
    if ( isShowSuspend && status==='preview' ) {
         globalContext.esHook.clearEsStateById(data.uniqueTicketId);
    }

    if(status==='successful' && prevEsValue.cashoutValue > 0){
        isShowSuspend = false ;
        cashoutDiv =textFormatAmountFn(prevEsValue.cashoutValue, 1)
    }

    const hasSettledLeg = (legs)=> {
        let hasSettledLeg = false
        for (let idx=0; idx<legs.length && !hasSettledLeg; idx++) {
            if (isSettledLeg(legs[idx], legs[idx].match) || isMultipLineHasSinglePayOut(legs[idx])) {
                hasSettledLeg = true;
            }
        }
        return hasSettledLeg;
    }
    let settleButton = hasSettledLeg(data.legs) && data.remainingBetTotal != data.ticketCostAmt ? t('LB_CASHOUT_SETTLE_REMAINING_NOW') : t('LB_ECASHOUT_BTN')

    return <div className='cashOutButtonContainer'>
        {(!isShowSuspend && !isShowPendingForPayout && !['preview','loading','suspended', 'suspend_rejected'].includes(status)) && !isESRejected && <div className={`cashOutbegins `}>
            {isResultsState && !isMobileSize && <div className='cashOutValue'><div>{t('LB_CASHOUT_VALUE')}:</div> <div>${cashoutDiv}</div></div>}
            <div className={`cashOutButton${status == 'successful' ? ' dimBg' : ''} ${isResultsState && 'cashOutButtonResult-d'}`} onClick={handleClickCashOutBtn}>
                <span className={`${status == rejected && 'rejectedBtnIcon'}`}>
                    {settleButton} ${cashoutDiv} {status == 'successful' ? t('LB_CASHOUT_CASHED_OUT') : ''}
                </span>
                {esValue?.indicator>0 || esValue?.indicator<0 ? <span className={`oddsArrow ${esValue?.indicator>0 ? "oddsUp":"oddsDown"}`}></span> : null}
            </div>
        </div>}
        {!isShowSuspend && !isShowPendingForPayout && status == 'preview' && <div className='cashOutPreview'>
            <div className='cashOutValue'><div>{t('LB_CASHOUT_VALUE')}:</div> <div>${formatedSubmittedESValue}</div></div>
            <div className='cashOutPreviewBtnArea'>
                <div className='cashOutPreviewBtn'>
                    <div className='cashOutButton' onClick={handleClickCancel}>{t('LB_CASHOUT_CANCEL_BTN')}</div>
                    <div onClick={() => {
                        handleClickConfirm();
                        glassboxCustomEvent("Cash_Out_by_Betline_Confirm");
                    }} className='cashOutButton '>{t('LB_CASHOUT_CONFIRM_BTN')}</div>
                </div>
            </div>
        </div>}
        {!isShowSuspend && !isShowPendingForPayout && status == 'loading' && <div className='cashOutSuccessful cashOutPreview'>
            <div className='cashOutValue'><div>{t('LB_CASHOUT_VALUE')}:</div> <div>${formatedSubmittedESValue}</div></div>
            <div className='cashOutButton loadingBtn'>{t('LB_CASHOUT_PROCESSED')}<div ref={loadingBtnMaskRef} className='loadingBtnMask'></div></div>

        </div>
        }
        {!isESRejected && (isShowPendingForPayout || isShowSuspend) ? <div className='cashOutPreview suspended'>
            <div className='cashOutSuspended'>{t(isShowPendingForPayout ? 'LB_CASHOUT_PENDING_FOR_PAYOUT' : 'LB_CASHOUT_SUSPENDED')}</div>
            <div className='cashOutButton dimBg fontS13'>{settleButton} $--</div>
        </div> : ''}
        {isESRejected ? <div className='cashOutbegins'>
            <div className='cashOutButton dimBg fontS13'>{settleButton} $--</div>
        </div> : ''}
    </div>
}

export default CashOutButton