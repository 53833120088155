import React, { useCallback, useContext } from 'react';

import HAD from '../Page/HAD';
import OFM from '../Page/OFM';
import FHA from '../Page/FHA';
import HHA from '../Page/HHA';
import HDC from '../Page/HDC';
import HIL from '../Page/HIL';
import FHL from '../Page/FHL';
import CHL from '../Page/CHL';
import FCH from '../Page/FCH';
import FTS from '../Page/FTS';
import CRS from '../Page/CRS';
import OOE from '../Page/OOE';
import MSP from '../Page/MSP';
import FCS from '../Page/FCS';
import FGS from '../Page/FGS';
import AGS from '../Page/AGS';
import LGS from '../Page/LGS';
import TTG from '../Page/TTG';
import HFT from '../Page/HFT';
import SGA from '../Page/SGA';
import CHD from '../Page/CHD';
import FHH from '../Page/FHH';
import FHC from '../Page/FHC';
import ALLODDS from '../Page/ALLODDS';
import INPLAY from '../Page/INPLAY';
import INPLAY_ALL from '../Page/INPLAY_ALL';
import Tournament from '../Page/Tournament';
import CHP from '../Page/CHP';
import TQL from '../Page/TQL';
import MIXALLUP from '../Page/MIXALLUP';
import MIXALLUP_DETAIL from '../Page/MIXALLUP_DETAIL';
import Results from '../Page/Results';
import FGSRES from '../Page/FGSRES';
import CHPRES from '../Page/CHPRES';
import TOURNRES from '../Page/TOURNRES';
import DHCRES from '../Page/DHCRES';
import HFMRES from '../Page/HFMRES';
import CASHOUT from '../../Home/CashOut';

import { Remark } from './MatchBase';
import NoPoolMsg from './NoPoolMsg';
import { FootballContext } from '../../Home/FootballPage';
import { isMatchResultPage, isTournResultPage } from './Common';
import { NoMatchResult } from './FBSearchButton'
import { useFBSearch } from './FBSearchHooks'
import { useTranslation } from 'react-i18next';
import Loading from '../../Common/ui-components/Loading';

const PageRoute = ({ isPoolDefined, fbSearchHooks }) => {

    const Content = useCallback(({isPoolDefined,fbSearchHooks}) => {
        const context = useContext(FootballContext);
        const { t } = useTranslation();
        const isSearching = context?.content?.selection?.type === 'SEARCH'
        const showNoResults = isSearching && (!context?.content?.matchData?.matches?.length) && context.content?.pageConfig?.showSearchTab && context?.content?.listData?.matches?.length
        const  handleClickViewAllMatch = fbSearchHooks?.handleClickViewAllMatch
        if (context.content.isLoading) {
            return <Loading/>
        }
        if(showNoResults){
            return <NoMatchResult searchAllMatch={handleClickViewAllMatch}/>
        }
        if (!isPoolDefined && !isMatchResultPage(context.content.page)
         && !isTournResultPage(context.content.page)
         && !context.content.config.MixAllUpDetailPages.includes(context.content.page)) {
            return <><NoPoolMsg /><Remark /></>;
        }

        switch (context.content.page) {
            case "OFM":
                return <OFM />
            case "HAD":
                return <HAD />
            case "FHA":
                return <FHA />
            case "FHH":
                return <FHH />
            case "HHA":
                return <HHA />
            case "HDC":
                return <HDC />
            case "HIL":
                return <HIL />
            case "FHL":
                return <FHL />
            case "CHL":
                return <CHL />
            case "FCH":
                return <FCH />
            case "FHC":
                return <FHC />
            case "CHD":
                return <CHD />
            case "CRS":
                return <CRS />
            case "FCS":
                return <FCS />
            case "FTS":
                return <FTS />
            case "OOE":
                return <OOE />
            case "TTG":
                return <TTG />
            case "HFT":
                return <HFT />
            case "MSP":
                return <MSP />
            case "SGA":
                return <SGA />
            case "FGS":
                return <FGS />
            case "LGS":
                return <LGS />
            case "AGS":
                return <AGS />
            case "ALLODDS":
                return <ALLODDS />
            case "INPLAY":
                return <INPLAY />
            case "INPLAY_ALL":
                return <INPLAY_ALL />
            case "CHP":
            case "FINALIST":
            case "WINCNTY":
            case "WINCNTT":
                return <CHP />
            case "TQL":
                return <TQL />
            case "TOURNAMENT":
                return <Tournament />
            case "MIXALLUP":
            case "MIXALLUP_DETAIL":
                return <MIXALLUP />
            case "RESULTS":
                return <Results />
            case "FGSRES":
            case "AGSRES":
            case "LGSRES":
                return <FGSRES />
            case "CHPRES":
                return <CHPRES />
            case "TOURNRES":
                return <TOURNRES />
            case "DHCRES":
                return <DHCRES />
            case "HFMRES":
                return <HFMRES />
            case "CASHOUT":
                return <CASHOUT />
            default:
                return <HAD />
        }
    }, [])

    return <>
        <Content isPoolDefined={isPoolDefined} fbSearchHooks={fbSearchHooks}/>
    </>

}
export default PageRoute;