import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IsFlexibetEnabled } from '../../Common/ChannelParaFunc';
import { RacingContext } from '../../Home/RacingPage';
import { textFormatAmountFn } from '../../Common/GlobalFunc';
import { unitBetText } from './Common';
import { UnsetBetSelsRandom } from './RacingBetline';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const InvestmentCalculator = ({ enable, className='border-radius-down' }) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);

    const [ expand, setExpand ] = useState(false);
    // const [ calState, setCalState ] = useState({
    //     unitBet: context.contentRef.current.unitBet,
    //     betTotal: context.contentRef.current.betTotal,
    //     flexibet: context.contentRef.current.flexibet
    // })
    const calState = context.contentRef.current.calState
    // console.log(context.contentRef.current.calState)
    useEffect(()=>{
        calState.flexibet = context.contentRef.current.flexibet;
        if ( context.contentRef.current.flexibet ) {
            calState.betTotal = context.contentRef.current.betTotal;
            let unitBet =  context.contentRef.current.betTotal!='-' && context.contentRef.current.betCount!='-' ? parseFloat(context.contentRef.current.betTotal / context.contentRef.current.betCount) : '-'
            if(unitBet === '-') calState.unitBet = '-'
            else{
                calState.unitBet = unitBet >= 0.01 ? Math.floor(unitBet * 100) / 100 : Math.floor(unitBet * 10000) / 10000;
            }
        }
        else {
            calState.unitBet = context.contentRef.current.unitBet;
            calState.betTotal = context.contentRef.current.unitBet!='-' && context.contentRef.current.betCount!='-' ? context.contentRef.current.unitBet * context.contentRef.current.betCount : '-';
        }
        context.racingFuncs.setContent({...context.contentRef.current, calState: {...calState}});
    }, [context.contentRef.current.betCount, context.contentRef.current.unitBet, context.contentRef.current.betTotal, context.contentRef.current.flexibet]);


    const handleClickExpand = e => {
        setExpand(!expand);
    }

    const onFocusTextBox = (e) => {
        if(e.target.value === "-") e.target.value = "";
        if ( context.windowSize.isMobileSize ) {
            e.preventDefault();
            e.target.setAttribute("data-init",1);
            e.target.removeAttribute('maxLength');
            e.target.setSelectionRange(10, 10)
            setTimeout(function(){
                e.target.setSelectionRange(10, 10)
            });
        }
    }

    const onClickBetTextbox = (e) => {
        if ( context.windowSize.isMobileSize ) {
            e.target.setSelectionRange(10, 10)
            setTimeout(function(){
                e.target.setSelectionRange(10, 10)
            });
        }
    };

    const onInputUnitBet = (e) => {
        let value = e.target.value;

        if(context.windowSize.isMobileSize && e.target.getAttribute("data-init") == 1) {
            if(e.nativeEvent.data !== null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute("data-init",0);
            e.target.setAttribute('maxLength', 10)
        }

        if (/\D/g.test(value)) {
            value = value.replace(/\D/g, "");
        }

        if (value <= 0) {
            calState.unitBet = "";
            calState.betTotal = "";
        } else {
            calState.unitBet = value;
            if ( context.contentRef.current.betCount != '-' ) {
                calState.betTotal = calState.unitBet * context.contentRef.current.betCount;
            }
        }
        calState.flexibet = false;
        UnsetBetSelsRandom();
        context.racingFuncs.setContent({...context.contentRef.current, calState: {...calState}});
    }

    const onInputBetTotal = (e) => {
        let value = e.target.value;

        if(context.windowSize.isMobileSize && e.target.getAttribute("data-init") == 1) {
            if(e.nativeEvent.data !== null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute("data-init",0);
            e.target.setAttribute('maxLength', 10)
        }

        if (/\D/g.test(value)) {
            value = value.replace(/\D/g, "");
        }

        if (value <= 0) {
            calState.unitBet = "";
            calState.betTotal = "";
        } else {
            calState.betTotal = value;
            if ( context.contentRef.current.betCount != '-' ) {
                let unitBet = parseFloat(calState.betTotal / context.contentRef.current.betCount)
                calState.unitBet = unitBet >= 0.01 ? Math.floor(unitBet * 100) / 100 : Math.floor(unitBet * 10000) / 10000;

            }
        }
        calState.flexibet = true;
        UnsetBetSelsRandom();
        context.racingFuncs.setContent({...context.contentRef.current, calState: {...calState}});
    }

    const onBlurUnitBet = () => {
        if (calState.unitBet > 0) {
            context.contentRef.current.unitBet = calState.unitBet;
            context.contentRef.current.betTotal = calState.betTotal;
            context.contentRef.current.flexibet = calState.flexibet;
            context.racingFuncs.setContent({...context.contentRef.current});
        }
        else {
            if(context.content.betCount != '-' && context.content.betCount > 0){
                calState.betTotal = context.contentRef.current.betTotal;
            }
            calState.unitBet = context.contentRef.current.unitBet;
            calState.flexibet = context.contentRef.current.flexibet;
            context.racingFuncs.setContent({...context.contentRef.current, calState: {...calState}});
        }
    }

    const onBlurBetTotal = () => {
        if (calState.betTotal > 0) {
            context.contentRef.current.unitBet = calState.unitBet;
            context.contentRef.current.betTotal = calState.betTotal;
            context.contentRef.current.flexibet = calState.flexibet;
            context.racingFuncs.setContent({...context.contentRef.current});
        }
        else {
            if(context.content.betCount != '-' && context.content.betCount > 0){
                calState.unitBet = context.contentRef.current.unitBet;
            }
            calState.betTotal = context.contentRef.current.betTotal;
            calState.flexibet = context.contentRef.current.flexibet;
            context.racingFuncs.setContent({...context.contentRef.current, calState: {...calState}});
        }
    }

    return <div className={`investment-cal ${enable ? '' : 'hide'} ${className}`}>
        <div className="investment-cal-lbl right-border" onClick={handleClickExpand}>{t('LB_RC_INVESTMENT_CALCULATOR')}</div>
        <div className="investment-cal-arow" onClick={handleClickExpand} >
            <ArrowIcon direction='down' change={expand} changeDirection='up' size={30} />
        </div>
        {(expand || !context.windowSize.isMobileSize) && <>
            <div className="investment-cal-betno">
                <div>{t('LB_RC_NO_OF_BETS')}:</div>
                <div>{context.content.betCount}</div>
            </div>
            <div className="investment-cal-unitbet">
                <div>{t('LB_RC_UNIT_BET')}</div>
                <div className='investment-cal-unitbet-input'>
                    <span >$</span><input maxLength="10" type="text" inputMode="numeric" className="unitBetInput" value={unitBetText(calState.unitBet, calState.flexibet)} onFocus={onFocusTextBox} onInput={onInputUnitBet} onBlur={onBlurUnitBet}/>
                </div>
            </div>
            <div className="investment-cal-bettotal">
                <div>{t('LB_BS_BET_TOTAL')}</div>
                <div className='investment-cal-unitbet-input'>
                    <span >$</span><input maxLength="10" type="text" inputMode="numeric" className="betTotalInput" disabled={!IsFlexibetEnabled(context.content.page)} value={textFormatAmountFn(calState.betTotal, 0, false)} onFocus={onFocusTextBox} onInput={onInputBetTotal} onBlur={onBlurBetTotal}/>
                </div>
            </div>
        </>}
    </div>
}
export default InvestmentCalculator;