import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AddSlipBtn from '../Common/AddSlipBtn';
import OddsTable from '../Common/RCOddsTableCompact';
import OddsDropLegend from '../Common/OddsDropLegend';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import Investment from '../Common/Investment';
import { ClearBetSels,GetBetSels, SetRandomBetSelect as SetBetSelect, RandomBanker, RandomLeg } from '../Common/RacingBetline';
import { getComingleMsgs, getMaxRunnerSize, getIsShowPmuText } from '../Common/Common';
import MultiLegSlider from '../Common/MultiLegSlider';
import ComingleMsg from '../Common/ComingleMsg'
import TTQuickPick from '../Common/TTQuickPick';
import { RacingContext } from '../../Home/RacingPage';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';
import HandleMultiLeg3xTablesTrHeight from '../Common/HandleMultiLeg3xTablesTrHeight';
import { useWindowSize } from '../../Common/CommonHooks';
import { isStartSellpmPool } from '../Common/Common';

const OddsTT = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const [isLogin, setIsLogin] = useState(true);
    const [randomKey, setRandomKey] = useState(1)
    const AddToSlipRef = useRef()
    HandleMultiLeg3xTablesTrHeight();
    const { width, isMobileSize } = useWindowSize();

    const RCColumns = {
        no: true,
        banker1: true,
        banker2: false,
        banker3: false,
        leg: true,
        horseName: true,
        rcNoteM: false,
        win: true,
        place: false,
        field: true,
        banker1F: false,
        banker2F: false,
        banker3F: false
    }

    const RCColumnLbls = {
        banker1: t('LB_RC_ODDS_BANKER'),
        leg: t('LB_RC_ODDS_SEL')
    }

    const randomTTQP = (val) => {
        const mtg = context.content.meeting;
        let pool =  mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType=="TT")[0];
        //if ( pool==null || pool.status != 'START_SELL')
        if ( pool==null || !isStartSellpmPool(pool))
            return null;
        let legList = pool.leg.races;
        let rList = [];
        let bList = [];
        let noOfBets
        switch (val.toString()) {
            case "0":
                const sels = []
                noOfBets = 2
                for(let i =0; i < 2; i++){
                    ClearBetSels();
                    rList = RandomLeg(mtg, legList[0], 3, []);
                    for (let idx in rList) {
                        if (rList.hasOwnProperty(idx)) {
                            SetBetSelect("TT", legList[0], "leg", rList[idx], true);
                        }
                    }

                    rList = RandomLeg(mtg, legList[1], 3, []);
                    for (let idx in rList) {
                        if (rList.hasOwnProperty(idx)) {
                            SetBetSelect("TT", legList[1], "leg", rList[idx], true);
                        }
                    }

                    rList = RandomLeg(mtg, legList[2], 3, []);
                    for (let idx in rList) {
                        if (rList.hasOwnProperty(idx)) {
                            SetBetSelect("TT", legList[2], "leg", rList[idx], true);
                        }
                    }
                    sels.push(GetBetSels())
                }

                AddToSlipRef.current.handleClickAddSlipBtn({qucikPickNoOfBets: noOfBets, quickPickSels: sels})
                break;
            case "1":
                ClearBetSels();
                noOfBets = 64
                rList = RandomLeg(mtg, legList[0], 4, []);
                for (let idx in rList) {
                    if (rList.hasOwnProperty(idx)) {
                        SetBetSelect("TT", legList[0], "leg", rList[idx], true);
                    }
                }

                rList = RandomLeg(mtg, legList[1], 4, []);
                for (let idx in rList) {
                    if (rList.hasOwnProperty(idx)) {
                        SetBetSelect("TT", legList[1], "leg", rList[idx], true);
                    }
                }

                rList = RandomLeg(mtg, legList[2], 4, []);
                for (let idx in rList) {
                    if (rList.hasOwnProperty(idx)) {
                        SetBetSelect("TT", legList[2], "leg", rList[idx], true);
                    }
                }
                break;
            case "2":
                ClearBetSels();
                noOfBets = 27
                bList = RandomBanker(mtg, legList[0], 1);
                rList = RandomLeg(mtg, legList[0], 3, bList);
                SetBetSelect("TT", legList[0], "b1", bList[0], true);
                for (let idx in rList) {
                    if (rList.hasOwnProperty(idx)) {
                        SetBetSelect("TT", legList[0], "leg", rList[idx], true);
                    }
                }

                bList = RandomBanker(mtg, legList[1], 1);
                rList = RandomLeg(mtg, legList[1], 3, bList);
                SetBetSelect("TT", legList[1], "b1", bList[0], true);
                for (let idx in rList) {
                    if (rList.hasOwnProperty(idx)) {
                        SetBetSelect("TT", legList[1], "leg", rList[idx], true);
                    }
                }

                bList = RandomBanker(mtg, legList[2], 1);
                rList = RandomLeg(mtg, legList[2], 3, bList);
                SetBetSelect("TT", legList[2], "b1", bList[0], true);
                for (let idx in rList) {
                    if (rList.hasOwnProperty(idx)) {
                        SetBetSelect("TT", legList[2], "leg", rList[idx], true);
                    }
                }
                break;
            case "3":
                ClearBetSels();
                noOfBets = 4
                rList = RandomLeg(mtg, legList[0], 4, []);
                for (let idx in rList) {
                    if (rList.hasOwnProperty(idx)) {
                        SetBetSelect("TT", legList[0], "leg", rList[idx], true);
                    }
                }

                rList = RandomLeg(mtg, legList[1], 3, []);
                for (let idx in rList) {
                    if (rList.hasOwnProperty(idx)) {
                        SetBetSelect("TT", legList[1], "leg", rList[idx], true);
                    }
                }

                rList = RandomLeg(mtg, legList[2], 3, []);
                for (let idx in rList) {
                    if (rList.hasOwnProperty(idx)) {
                        SetBetSelect("TT", legList[2], "leg", rList[idx], true);
                    }
                }
                break;
        }
        setRandomKey(randomKey+1);
        if(val.toString()!=='0'){
            context.racingFuncs.resetInvCal();
        }
    }

    if (context == null) {
        return <Loading/>
    } else {
        const mtg = context.content.meeting;
        let pool =  mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType=="TT")[0];
        if ( pool==null )
            return null;

        let legList = pool.leg.races;
        // const defTableSize = Math.max(14, getMaxRunnerSize(context, legList, 0, 3));

        let tmpMsgs = getComingleMsgs(mtg, context.content.page, context.content.raceNo);
        const isShowPmu = getIsShowPmuText(mtg, context.content.raceNo)
        let pageConfig = context.pageConfig;
        let comingleMsg1 = [];
        if (tmpMsgs != null) {
            let comingleLang = i18n.language == "en" ? "E" : "C";
            if (tmpMsgs['NOTE1_' + comingleLang] != '')
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE1_' + comingleLang]}</div>);
            if (tmpMsgs['NOTE2_' + comingleLang] != '')
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
            if (tmpMsgs['NOTE3_' + comingleLang] != '')
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}3: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
        }

        return (
            <section className={context.content.page}>
                <Investment betType={context.content.page} showJpt={true} showEstDiv={true} />
                <InvestmentCalculator enable={true} className=''/>
                <TTQuickPick handleRandomTTQP={randomTTQP} mobile="" />
                <TTQuickPick handleRandomTTQP={randomTTQP} mobile="-m" />
                <RefreshTime product="racing" />
                <MultiLegSlider cnt={legList.length} className="multi-leg-3x-table" key={randomKey}>
                    {legList.map((_cItem, _cIndex) => {
                        let defTableSize = 14;
                        let group = width < 1280 ? 2 : 3;
                        const start = parseInt(_cIndex / group) * group
                        const end = (parseInt(_cIndex / group) + 1) * group
                        defTableSize = Math.max(defTableSize, getMaxRunnerSize(context, legList, start, end));
                        return <OddsTable betType={context.content.page} columns={RCColumns} lbl={RCColumnLbls}
                            firstRace={legList[0]} raceNo={_cItem} startCnt={1}
                            selectedBetType={context.content.page} showRaceHeader="true" defTableSize={defTableSize} />
                    })}
                </MultiLegSlider>
                {isShowPmu && <div className="rc-comingle-remarks"><ComingleMsg textId='tt_pmu_wrap' linkId='tt_pmu_link'></ComingleMsg></div>}
                <OddsDropLegend />
                <div className="multi-leg-footer-betSlip">
                    <div ><AddSlipBtn ref={AddToSlipRef} /></div>
                </div>
                <InvestmentCalculator enable={!context.windowSize.isMobileSize} />
            </section>
        );
    }
}
export default OddsTT;