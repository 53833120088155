import React, { useEffect, useState, useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactRadio from '../../../Common/ui-components/ReactRadio';
import AmountInput from '../../../Common/ui-components/AmountInput';
import { textFormatAmountFn } from '../../../Common/GlobalFunc';
import FPSBanner from '../components/FPSBanner';
import ArrowIcon from '../../../Common/ui-components/ArrowIcon';
import { formatServiceCharge } from '../utils';
import EpsNotice from '../EpsNotice';
import { ConfigContext } from '../../../Common/ConfigHelper';

const DepositForm = ({ props }) => {
    const {
        loginAccount,
        goToLinkBAPage,
        curStep,
        setCurStep,
        bankInfo,
        depositAmount,
        setDepositAmount,
        transactionMethod,
        setTransactionMethod,
        nbaData,
        updateDataShow,
        showOtherMeans,
        setShowOtherMeans,
        ishadOtherMeans,
        chargeAmtFps,
        chargeAmtEps,
        setSaveEpsNotice,
        ppsBtnState,
        bankOrPpsAccType,
        setBankOrPpsAccType,
        getExpCaseStatus,
        isMobileSize,
        isMassSite,
        bankFir,
        bankSec,
        bankFirShow,
        bankSecShow,
        depositConfig,
        isEnablePPSG,
        fpsMessageQuery
    } = props;
    const { t, i18n } = useTranslation();
    const [proceedEft, setProceedEFT] = useState(false);
    const [showReminder, setShowReminder] = useState({ open: false, status: '', params: {} });
    const { betslipMenu } = useContext(ConfigContext);

    const langName = i18n.language === 'en' ? 'name_en' : 'name_ch';
    const methodFps = bankInfo?.['FPS'];
    const methodEps = bankInfo?.['EPS'];
    const configFps = depositConfig?.['FPS'];
    const configEpsco = depositConfig?.['EPS'];
    const configPps = depositConfig?.['PPS'];
    const methodFpsShow = methodFps?.show;
    const methodEpsShow = methodEps?.show;
    const curMethodIsFps = transactionMethod === 'FPS';
    const curMethodIsEps = transactionMethod === 'EPS';
    const fpsEFTMessage = fpsMessageQuery?.jcbwControl?.fpsBannerControl?.fpsEFTMessage['name_' + i18n.language]?.replace(/\n/g,'<br/>');

    let minAmount = configEpsco?.TBMinDeposit;
    let maxAmount = configEpsco?.TBMaxDeposit;
    let amountValidMsg = t('LB_BS_FT_DEP_AMTERRMSG')
        .replace('{0}', t('LB_BS_FT_EPS').replace('{0}', t('LB_BS_FT_DEPOSIT')))
        .replace('{1}', minAmount)
        .replace('{2}', maxAmount);
    if (curMethodIsFps && configFps) {
        minAmount = configFps.perDepMinAmt;
        maxAmount = configFps.perDepMaxAmt;
        amountValidMsg = t('LB_BS_FT_DEP_AMTERRMSG')
            .replace('{0}', t('LB_BS_FT_FPS').replace('{0}', t('LB_BS_FT_DEPOSIT')))
            .replace('{1}', minAmount)
            .replace('{2}', maxAmount);
    }

    const FPSDisabled = methodFps?.disabled;
    const curIsFPSAndDim = curMethodIsFps && FPSDisabled;
    const isShowPpsEodMsg = !ppsBtnState && configPps?.MinutesBeforeEOD;
    const curIsBankType=bankOrPpsAccType==='bankType';
    const curIsPpsType=bankOrPpsAccType==='ppsType';



    useEffect(() => {
        if (proceedEft) {
            setCurStep(1);
        }
    }, [proceedEft]);

    const onChangeBankInfo = (value) => {
        updateDataShow(value);
        setDepositAmount('');
    };
    const onChangeTransactionMethod = (value) => {
        setTransactionMethod(value);
    };

    const onInputAmount = (value) => {
        setDepositAmount(value);
    };

    const handleClickShowOtherMeans = () => {
        setShowOtherMeans(!showOtherMeans);
    };

    const handleClickNextBtn = () => {
        if (curIsBankType && curIsFPSAndDim) {
            return;
        }
        if (!depositAmount) {
            return alert(t('LB_BS_FT_AMOUNT_ERR_MSG1'));
        }
        let minAmt = minAmount - 0;
        let depositAmt = depositAmount - 0;
        let maxAmt = maxAmount - 0;
        if (minAmt > depositAmt || maxAmt < depositAmt) {
            return alert(amountValidMsg);
        }
        //check EPS service fee notification
        if (
            transactionMethod === 'EPS' &&
            chargeAmtEps > 0 &&
            !hasEpscoNoticeCookie() &&
            betslipMenu?.enableEpsChargeNotification
        ) {
            setShowReminder({ open: true, status: '', params: {} });
            return;
        }
        setCurStep(1);
    };

    const handleClickHereBtn = () => {
        goToLinkBAPage(1, bankInfo.bankAccountType);
    };

    const hasEpscoNoticeCookie = () => {
        var match = document.cookie.match(new RegExp('(^| )' + 'NoEpsNotice_' + loginAccount + '=([true;]+)'));
        if (match) {
            return true;
        } else {
            return false;
        }
    };

    const ppsCheckOtherMeans = () => {
        window.open(t('LB_BS_FT_PPS_CHECK_OTHERMEANS_URL'));
    }

    const changeAccType = (type) => {
        if((bankFirShow || bankSecShow) && type === 'bankType' && !curIsBankType){
            setBankOrPpsAccType('bankType')
            setDepositAmount('');
        } else if(ppsBtnState && type === 'ppsType' && !curIsPpsType){
            setBankOrPpsAccType('ppsType')
            setDepositAmount('');
        }
    }

    if (curIsPpsType) {
        minAmount = configPps?.TransferLowerRange;
        maxAmount = configPps?.TransferUpperRange;
        amountValidMsg = t('LB_BS_FT_AMOUNT_ERR_MSG4')
            .replace('{0}', minAmount)
            .replace('{1}', maxAmount);
    }

    let singleLegMsg = <></>;

    if (bankInfo?.FPS?.isSingleLeg) {
        singleLegMsg = (
            <div className="singleLegMsg">
                *{t('LB_BS_FT_SINGLELEG_FPSMSG1')}
                <span className="text-underline" onClick={handleClickHereBtn}>
                    {t('LB_BS_FT_SINGLELEG_FPSMSG2')}
                </span>
                {t('LB_BS_FT_SINGLELEG_FPSMSG3')}
            </div>
        );
    }

    const isDimAmountAndNext = (curIsBankType && curIsFPSAndDim) || (curIsPpsType && !ppsBtnState) || (!curIsBankType && !curIsPpsType)

    return (
        <div className="deposit-form">
            <div className="FT-form-content">
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_FT_FROM')}:</div>
                    <div className="formItemContent">
                        { !isMobileSize ?
                            <div className='eftAccountBtnArea'>
                                { ['noNba','noFps'].includes(getExpCaseStatus) ? <>
                                    {curIsPpsType?
                                        <div
                                            className={`eftAccountBtn active `}
                                        >{t('LB_BS_FT_LINK_PPS')}</div>
                                    :
                                        <div className={`bankInfo method-dim`} >
                                            <div className="contentTop" title={isShowPpsEodMsg ?t('LB_BS_FT_PPS_ISEOD'):null} >{t('LB_BS_FT_LINK_PPS')}</div>
                                        </div>
                                    }
                                </>
                                :
                                    <>
                                        <div className={`eftAccountBtn ${curIsBankType?'active':''} ${(bankFirShow || bankSecShow) ?'':'disabled'}`} onClick={()=>changeAccType('bankType')}>{t('LB_BS_FT_LINK_NBA')}</div>
                                        {isMassSite && isEnablePPSG &&
                                            <div
                                                className={`eftAccountBtn ${curIsPpsType?'active':''} ${ppsBtnState?'':'disabled'}`}
                                                title={isShowPpsEodMsg ?t('LB_BS_FT_PPS_ISEOD'):null}
                                                onClick={()=>changeAccType('ppsType')}
                                            >{t('LB_BS_FT_LINK_PPS')}</div>
                                        }
                                    </>
                                }
                            </div>
                        :null}
                        {bankFirShow && curIsBankType && (
                            <div className={`itemMult`}>
                                {bankSecShow && (
                                    <ReactRadio
                                        checked={bankInfo.bankAccountNo === bankFir.bankAccountNo}
                                        onChange={() => onChangeBankInfo(bankFir)}
                                        disabled={nbaData.nba1?.FPS?.disabled && !nbaData.nba1?.EPS.show}
                                    />
                                )}
                                <div
                                    className={`bankInfo ${
                                        nbaData.nba1?.FPS?.disabled && !nbaData.nba1?.EPS.show && 'method-dim'
                                    } `}
                                >
                                    <div className="contentTop">{bankFir?.bankInfo?.[langName]}</div>
                                    <div
                                        className={`contentBottom ${
                                            nbaData.nba1?.FPS?.disabled && !nbaData.nba1?.EPS.show && 'method-dim'
                                        } `}
                                    >
                                        {bankFir.bankAccountNoMasked}
                                    </div>
                                </div>
                            </div>
                        )}
                        {bankSecShow && curIsBankType && (
                            <div className={`itemMult`}>
                                {bankFirShow && (
                                    <ReactRadio
                                        checked={bankInfo.bankAccountNo === bankSec.bankAccountNo}
                                        onChange={() => onChangeBankInfo(bankSec)}
                                        disabled={nbaData.nba2?.FPS?.disabled && !nbaData.nba2?.EPS.show}
                                    />
                                )}
                                <div
                                    className={`bankInfo ${
                                        nbaData.nba2?.FPS?.disabled && !nbaData.nba2?.EPS.show && 'method-dim'
                                    } `}
                                >
                                    <div className="contentTop">{bankSec?.bankInfo?.[langName]}</div>
                                    <div
                                        className={`contentBottom ${
                                            nbaData.nba2?.FPS?.disabled && !nbaData.nba2?.EPS.show && 'method-dim'
                                        } `}
                                    >
                                        {bankSec.bankAccountNoMasked}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_FT_TO')}:</div>
                    <div className="formItemContent">
                        <div className="contentTop">{t('LB_BS_FT_BETACCOUNT')}</div>
                        <div className="contentBottom">{loginAccount}</div>
                    </div>
                </div>
                {bankInfo && ishadOtherMeans && curIsBankType && (
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_TRANSACTIONMETHOD')}:</div>
                        <div className="formItemContent">
                            {methodFpsShow && (showOtherMeans || curMethodIsFps || FPSDisabled) && (
                                <div className={`itemMult ${FPSDisabled && 'method-dim'}`}>
                                    {methodEpsShow && (
                                        <ReactRadio
                                            checked={curMethodIsFps}
                                            onChange={() => onChangeTransactionMethod('FPS')}
                                            disabled={FPSDisabled}
                                        />
                                    )}
                                    <div className="bankInfo">
                                        <div className="contentTop">
                                            {t('LB_BS_FT_FPS').replace('{0}', t('LB_BS_FT_DEPOSIT'))}{' '}
                                        </div>
                                        <div className="contentBottom">
                                            {FPSDisabled ? (
                                                <span className="method-dim" dangerouslySetInnerHTML={{__html: fpsEFTMessage?.length > 0
                                                        ? fpsEFTMessage
                                                        : t('LB_BS_FT_FPS_REMARK3') }}>
                                                </span>
                                            ) : (
                                                <>
                                                    <div>
                                                        {t('LB_BS_FT_FPS_REMARK4').replace(
                                                            '{0}',
                                                            textFormatAmountFn(depositConfig?.['FPS']?.perDepMinAmt, 0)
                                                        )}
                                                    </div>
                                                    {chargeAmtFps > 0 && (
                                                        <div className="contentBlue">
                                                            {t('LB_BS_FT_EPS_REMARK5').replace(
                                                                '{0}',
                                                                formatServiceCharge(chargeAmtFps)
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {singleLegMsg}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {methodEpsShow && (showOtherMeans || curMethodIsEps) && (
                                <div className="itemMult">
                                    {methodFpsShow && (
                                        <ReactRadio
                                            checked={curMethodIsEps}
                                            onChange={() => onChangeTransactionMethod('EPS')}
                                        />
                                    )}
                                    <div className="bankInfo">
                                        <div className="contentTop">
                                            {t('LB_BS_FT_EPS').replace('{0}', t('LB_BS_FT_DEPOSIT'))}{' '}
                                        </div>
                                        <div className="contentBottom">
                                            {t('LB_BS_FT_EPS_REMARK4').replace(
                                                '{0}',
                                                textFormatAmountFn(configEpsco?.TBMinDeposit, 0)
                                            )}
                                        </div>
                                        {chargeAmtEps > 0 ? (
                                            <div className="contentBottom contentBlue">
                                                {t('LB_BS_FT_EPS_REMARK5').replace(
                                                    '{0}',
                                                    formatServiceCharge(chargeAmtEps)
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                            {methodFpsShow && methodEpsShow && !FPSDisabled && (
                                <div className="otherMeansBtn" onClick={handleClickShowOtherMeans}>
                                    <span>{showOtherMeans ? t('LB_BS_FT_SHOWLESS') : t('LB_BS_FT_OTHERMEANS')}</span>
                                    <ArrowIcon
                                        className=""
                                        direction="down"
                                        change={showOtherMeans}
                                        changeDirection="up"
                                        size={24}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!curIsBankType && isMassSite && (
                    <div className="formItem">
                        <div className="formItemLabel">{t('LB_BS_FT_TRANSACTIONMETHOD')}:</div>
                        <div className="formItemContent">
                            <div className="contentTop">{t('LB_BS_FT_LINK_PPS_INSTANT')}</div>
                            <div className="contentBottom">
                                {t('LB_BS_FT_FPS_REMARK4').replace(
                                    '{0}',
                                    textFormatAmountFn(configPps?.TransferLowerRange, 0)
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className="formItem">
                    <div className="formItemLabel">{t('LB_BS_FT_DEPOSITAMOUNT')}:</div>
                    <div className="formItemContent">
                        <div className="contentTop inputContainer">
                            <AmountInput onChange={onInputAmount} disabled={isDimAmountAndNext} value={depositAmount} />
                        </div>
                        <div className="contentBottom">
                            {curIsBankType?(
                                <>
                                    {curMethodIsFps ? (
                                        <>
                                            {!ishadOtherMeans && (
                                                <div>
                                                    {t('LB_BS_FT_DEP_FPS_CASE_ON').replace(
                                                        '{0}',
                                                        textFormatAmountFn(minAmount, 0)
                                                    )}
                                                </div>
                                            )}
                                            {!FPSDisabled && (
                                                <div dangerouslySetInnerHTML={{ __html: t('LB_BS_FT_DEP_FPS_CASE') }}></div>
                                            )}
                                        </>
                                    ) : (
                                        <div>
                                            {!ishadOtherMeans &&
                                                t('LB_BS_FT_DEP_EPS_CASE').replace('{0}', textFormatAmountFn(minAmount, 0))}
                                        </div>
                                    )}
                                </>
                            ):
                            null
                            }
                            {curIsPpsType ? (
                                <div>
                                    {t('LB_BS_FT_DEP_PPS_CASE').replace('{0}', textFormatAmountFn(configPps?.PpsCharge, 0))}
                                </div>
                            ):null}
                        </div>
                    </div>
                </div>
            </div>
            {curIsPpsType &&
                <div className='ppsCheckOtherMeans' >
                    <span onClick={ppsCheckOtherMeans} >
                        {t('LB_BS_FT_PPS_CHECK_OTHERMEANS')}
                    </span>
                </div>
            }
            <FPSBanner fpsBannerQuery={props.fpsBannerQuery} />
            <div className="deposit-btnArea">
                <div className={`deposit-btn ${isDimAmountAndNext ? 'fpsDim' : 'nextBtn'}`} onClick={handleClickNextBtn}>
                    {t('LB_BS_FT_NEXT')}
                </div>
            </div>
            <EpsNotice
                chargeAmtEps={chargeAmtEps}
                setProceedEFT={setProceedEFT}
                loginAccount={loginAccount}
                goToLinkBAPage={goToLinkBAPage}
                bankInfo={bankInfo}
                showReminder={showReminder}
                setShowReminder={setShowReminder}
                setSaveEpsNotice={setSaveEpsNotice}
                linkDisabled={nbaData.config.FPS.linkDisabled}
            ></EpsNotice>
        </div>
    );
};

export default DepositForm;
