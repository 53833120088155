import React, { useEffect, useState } from 'react';
import MoreButton from '../MoreButton'
import './index.scss'
const Table =({columns=[],data=[],DefaultDisplayumber, className,showLessText='LB_FB_SHOW_LESS_TEXT',showMoreText='LB_FB_SHOW_MORE_TEXT',id})=>{
    const [isShow,setIsShow] = useState(DefaultDisplayumber!=undefined && data.length>3) 
    const [showStatus,setShowStatus] = useState(false)
    const newData = ((isShow && !showStatus) ? data.slice(0, DefaultDisplayumber): data)
    return <><table className={`jcbwTable ${className}`}>
        <thead>
            <tr>
                {columns.map(i=>{
                    return <th>{i.title}</th>
                })}
                
            </tr>
        </thead>
        <tbody>
            {newData.map(rowData=>{
                return <tr>
                    {columns.map(i=>{
                        if (typeof i.onCell === 'function') {
                            return <td>{i.onCell(rowData, {parentData:data})}</td>
                        }
                        return rowData[i.key]
                    })}
                </tr>
            })}
        </tbody>
    </table>
    {isShow && <MoreButton id={id+'_showButton'} showLessText={showLessText} showMoreText={showMoreText} MoreButtonClick={()=>{setShowStatus(!showStatus)}} expand={showStatus} showLine={newData.length % 2}></MoreButton>}
    </>
}
export default Table