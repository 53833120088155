import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ComingleMsg from '../Common/ComingleMsg'
import PoolInvTable from '../Common/PoolInvTable';
import {getComingleMsgs, isStartSell, getIsShowPmuText} from '../Common/Common';
import { RacingContext } from '../../Home/RacingPage';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';

const Turnover = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);


    if (context == null) {
        return <Loading/>
    } else {
        const mtg = context.content.meeting;
        const isShowPmu = getIsShowPmuText(mtg, context.content.raceNo)
        let tmpMsgs = getComingleMsgs(mtg, "TO", context.content.raceNo);
        let comingleMsg1 = [];
        if ( tmpMsgs!=null ) {
            let comingleLang = i18n.language==="en" ? "E" : "C";
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{fontWeight:"bold"}}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE1_' + comingleLang]}</div>);
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{fontWeight:"bold"}}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
        }
        
        return (

            <section className={context.content.page}>
                <RefreshTime product="racing" />
                <PoolInvTable />
                {
                isShowPmu && isStartSell(mtg) && <div className="rc-comingle-remarks"><ComingleMsg
                textId='turnover_pmu_wrap' linkId='turnover_pmu_link'
                ></ComingleMsg></div>
                }
                {/* {mtg?.poolInvs && isStartSell(mtg) ? comingleMsg1 : null} */}
            </section>
        );
    }
}
export default Turnover;